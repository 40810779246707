import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { Paragraph } from 'components/1-atoms/Paragraph';
import styles from './HeadingCard.module.scss';

export interface HeadingCardProps {
	className?: string;
	heading?: string;
	text?: string;
	noTopMargin?: boolean;
	noBottomMargin?: boolean;
}

export const HeadingCard: React.FC<HeadingCardProps> = ({ className, heading, text, noTopMargin = false, noBottomMargin = true }) => (
	<div
		className={classNames(
			styles.HeadingCard,
			{ [styles.HeadingCard___noTopMargin]: noTopMargin, [styles.HeadingCard___noBottomMargin]: noBottomMargin },
			className,
		)}
	>
		<div className={styles.HeadingCard_wrapper}>
			<Heading className={styles.HeadingCard_heading} headingLevel="h1" style="xl">
				{heading}
			</Heading>

			{text && <Paragraph text={text} className={styles.HeadingCard_text} />}
		</div>
	</div>
);
